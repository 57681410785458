import { quoteAPI } from '../api/quoteAPI';

import Swal from 'sweetalert2';

//Quote Slice
import { setQuery, setLoading, setError } from './reducers/querySlice';

export const setJobDescription = (quote, images) => (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true
        
        //Set the Query
        dispatch(setQuery(quote, images));
                
    } catch (error) {
      dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
      dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};