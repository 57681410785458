import { userAPI } from '../api/userAPI';
import { quoteAPI } from '../api/quoteAPI';

//SweetAlert
import Swal from 'sweetalert2';

//User Slice
import { setUser, setLoading, setError } from './reducers/userSlice';

//Query Slice
import { setQuery } from './reducers/querySlice';

/*********************** User Redux *************************/
export const registerUser = (name, email, password, address, phone, latitude, longitude, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true
        
        userAPI.register(name, email, password, address, phone, latitude, longitude).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 201) {
               
                dispatch(setUser(response.data.user)); // Dispatch action to set current user

                //Store the token in the local storage
                localStorage.setItem('C-TradesUserToken', response.data.tokens.refresh.token);

                //Add a SweetAlert2 to confirm registration before navigating
                Swal.fire({
                    icon: 'success',
                    title: 'Registration successful',
                    text: 'Please continue to proceed to your dashboard.',
                    showConfirmButton: true,
                    confirmButtonText: 'Continue',
                    showCancelButton: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Navigate to the dashboard
                        navigate(`/dashboard`);
                    }
                });
            }
        }).catch((error) => {

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
      dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
      dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Login User
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
export const loginUser = (email, password) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        userAPI.login(email, password).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('C-TradesUserToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.message,
            });
            //console.log(error.response.data.message);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};
  
/**
 * Check Browser Storage to auto login user
 * @param {*} token 
 * @returns 
 */
export const tokenLoginUser = (token, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true)); // Set loading state to true

        userAPI.checklogintoken(token).then((response) => {
            
            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                const user = response.data.user;

                if (user._id !== undefined) {
                    user.id = user._id;
                }
                else if (user.id !== undefined) {
                    user._id = user.id;
                }
                
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            }
        }).catch((error) => {
            //Navigate to login
            navigate(`/`);
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * This function obtains the user's data through a Google login
 * @param {*} email
 */
export const googleLoginUser = (email) => async (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        userAPI.checkGoogleLogin(email).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 200) {
                    
                dispatch(setUser(response.data.user)); // Dispatch action to set current user
            
                //Store the token in the local storage
                localStorage.setItem('C-TradesUserToken', response.data.tokens.refresh.token);
            }
        }).catch((error) => {    
            //console.log(error.response.data.error);
            //console.log(error.response.data.message);

            Swal.fire({
                icon: 'error',
                title: 'Login error',
                text: error.response.data.error,
            });
        });
    }
    catch (error) {
        dispatch(setError(error.response.data.error)); // Dispatch action to store error
    }
    finally {
        dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Onboarding user with more fields to fill in 
 * @param {*} id 
 * @param {*} name 
 * @param {*} password 
 * @param {*} image 
 * @param {*} city 
 * @param {*} state 
 * @param {*} country 
 * @param {*} latitude 
 * @param {*} longitude 
 * @param {*} interests 
 * @returns 
 */
//Onboard User
export const onboardUser = (description, name, phone, email, address, lat, long, urgency, images) => (dispatch) => {

    try {
        dispatch(setLoading(true)); // Set loading state to true

        quoteAPI.createRequest(description, name, phone, email, address, lat, long, urgency, images).then((response) => {

            //Check to see if there are any Hubs
            if (response.status === 201) {
                
                console.log(response.data);

                dispatch(setUser(response.data)); // Dispatch action to set current user
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Onboarding error',
                text: error.response.data.message,
            });
            
        });
    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};

/**
 * Gets ChatGPT response to query
 * @param {*} token 
 * @returns 
 */
export const getChatGPTResponse = (query) => async (dispatch) => {
    try {

        const response = await quoteAPI.callChatGPT(query);
        console.log(response);

        dispatch(setQuery(query)); // Dispatch action to set current user

    } catch (error) {
        dispatch(setError(error.message)); // Dispatch action to store error
    } finally {
            dispatch(setLoading(false)); // Set loading state to false (optional)
    }
};