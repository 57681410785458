import { api } from "./config/axiosConfig"

export const userAPI = {
  
  login: async function (email, password) {

    const response = await api.request({
      url: `/auth/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  register: async function (name, email, password, address, phone, latitude, longitude) {

    const response = await api.request({
      url: `/auth/register`,
      method: "POST",
      data: {
        name: name,
        email: email,
        password: password,
        address: address,
        phone: phone,
        latitude: latitude,
        longitude: longitude
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // Persistent Login - checking user token
  checklogintoken: async function (token) {

    const response = await api.request({
      url: `/auth/check-token`,
      method: "POST",
      data: {
        token: token,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  // User Login - via Google
  checkGoogleLogin: async function (email) {

    const response = await api.request({
      url: `/auth/check-login`,
      method: "POST",
      data: {
        email: email,
      },
    })
    
    // returning the product returned by the API
    return response
  },

  resetPassword: async function (token, password) {

    const response = await api.request({
      url: `/auth/reset-password`,
      method: "POST",
      params: {
        token: token,
      },
      data: {
        password: password,
      },
    })

    // returning the product returned by the API
    return response
  },

  forgotPassword: async function (email) {

    const response = await api.request({
      url: `/auth/forgot-password`,
      method: "POST",
      data: {
        email: email,
      },
    })

    // returning the product returned by the API
    return response
  },
    
};