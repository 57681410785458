import * as React from 'react';
import { useState, useRef, useEffect } from "react";

//MUI
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

//Axios
import axios from 'axios';

//Images
import Background from '../../assets/images/onboard.jpg';
import SuccessBackground from '../../assets/images/confirm.jpg';

//Styling
import '../../css/onBoard.css'

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, onboardUser } from '../../redux/userActions'; // Import actions

//Images
import Logo from '../../assets/images/C-TRADES.png';

//SweetAlert
import Swal from 'sweetalert2';

//Loading
import ReactLoading from 'react-loading';
import { quoteAPI } from '../../api/quoteAPI';

//Lottie
import Lottie from 'lottie-react';
import success from '../../assets/success.json';

//Navigation
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//Google Analytics
import ReactGA from 'react-ga4';

const KeyCodes = {
    comma: 188,
    enter: 13
};

function OnBoard(props) {

    //Variables
    const [GPTdescription, setGPTDescription] = useState('');

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address, setAddress] = React.useState('');

    const [phone, setPhone] = React.useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [urgency, setUrgency] = useState('2');

    //Loading after button pressed
    const [loading, setLoading] = useState(false);

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const currentQuery = useSelector((state) => state.query.currentQuery);

    //Navigation
    const navigate = useNavigate();

    //Get Props
    const location = useLocation();
    const { description, images } = location.state || {};

    //Google Places API
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        types: ['geocode'],
        componentRestrictions: { country: ["ca","us"] }
    };

    const pageStyle = {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const successStyle = {
        backgroundImage: `url(${SuccessBackground})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/onboard", title: "Customer onboarding" });

    //Use Effect for Google Places API
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(document.getElementById('home_address'), options);
        autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }, []);

    const handlePlaceSelect = () => {
        const addressObject = autoCompleteRef.current.getPlace();
        const address = addressObject.address_components;

        if (address) {

            setAddress(addressObject.formatted_address);

            //Get the city and state
            for (let i = 0; i < address.length; i++) {
                if (address[i].types.includes('locality')) {
                    setCity(address[i].long_name);
                }
                if (address[i].types.includes('administrative_area_level_1')) {
                    setState(address[i].long_name);
                }
                if (address[i].types.includes('country')) {
                    setCountry(address[i].long_name);
                }
            }

            setLatitude(addressObject.geometry.location.lat());
            setLongitude(addressObject.geometry.location.lng());
        }
    }

    //When the user clicks on the Submit button
    const handleSubmit = (e) => {
        e.preventDefault();
        
        //Make sure home address is filled in
        if (autoCompleteRef.current.getPlace() === undefined) {

            Swal.fire({
                icon: 'error',
                title: 'Address error',
                text: 'Please enter your home address to match with nearby businesses',
            });

            return;
        }

        //If Name is not present on user, then it needs to be filled in
        else if (name.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Name error',
                text: 'Please enter your name',
            });

            return;
        }

        //If Phone is not present on user, then it needs to be filled in
        else if (phone.length < 1) {
                
            Swal.fire({
                icon: 'error',
                title: 'Phone error',
                text: 'Please enter your phone number',
            });

            return;
        }

        //If Email is not present on user, then it needs to be filled in
        else if (email.length < 1) {

            Swal.fire({
                icon: 'error',
                title: 'Email error',
                text: 'Please enter your email address',
            });
            
            return;
        }



        else {  
            //Set Loading
            setLoading(true);

            let urgencyText = '';

            console.log(urgency);

            if (urgency === 1) {
                urgencyText = "24 hours";
            }
            else {
                urgencyText = "Flexible";   
            }

            //Send to the server to create the request along with the images
            dispatch(onboardUser(currentQuery, name, phone, email, address, latitude, longitude, urgencyText, images));

            //dispatch(onboardUser(currentQuery, name, phone, email, address, latitude, longitude, urgencyText, null));
        }
        
    };

    //Create an Account
    const createAccount = () => {

        //Create Google Analytics Event
        ReactGA.event({
            category: 'User',
            action: 'Create Account'
        });
        
        Swal.fire({
            title: 'Registration',
            html: `
              <div class="custom-swal-container">
                <img src="${Logo}" alt="Logo" style="width: 100px; margin-bottom: 10px;">
                <input type="text" id="password1" class="swal2-input" placeholder="Password">
                <input type="text" id="password2" class="swal2-input" placeholder="Confirm Password">
                <div class="tos-checkbox">
                    <input type="checkbox" id="tos" class="largeCheckbox">
                    <label for="tos">I agree to the <a href="https://quotes.co-owners.ca/TC.html" target="_blank">Terms of Service</a></label>
                </div>
              </div>
            `,
            confirmButtonText: 'Register',
            focusConfirm: false,
            customClass: {
                popup: 'custom-swal-height'
            },
            preConfirm: () => {
              const password1 = Swal.getPopup().querySelector('#password1').value
              const password2 = Swal.getPopup().querySelector('#password2').value
              const tosChecked = Swal.getPopup().querySelector('#tos').checked;

              if (!password1 || !password2) {
                Swal.showValidationMessage(`Please enter a password`);
              }
              if (password1 !== password2) {
                Swal.showValidationMessage('Passwords do not match');
              }
              if (!tosChecked) {
                Swal.showValidationMessage('You must agree to the Terms of Service, please check the box');
              }

              return { password1: password1, password2: password2 }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const password = result.value.password1;
                
                //Dispatch the action to create the user along with the images
                dispatch(registerUser(name, email, password, address, phone, latitude, longitude, navigate));

                //Dispatch the action to create the user
                //dispatch(registerUser(name, email, password, address, phone, latitude, longitude, navigate));
            
                //Create Google Analytics Event
                ReactGA.event({
                    category: 'User',
                    action: 'Create Account Success'
                });
            }
        });
    }

    if ((!loading) && (!currentUser)) {

        return (
            <div style={pageStyle}>
                <img src={Logo} className='logo' alt="C-Trades Logo" />

                <div className='formPanel'>
                    <div className='needPanel'>
                        <div className='needTitle'>Your repair or renovation need:</div>
                        <div className='needResponse'>{ currentQuery }</div>
                    </div>

                    <div className="image-preview">
                        {images && images.map((file, index) => (
                            <img
                                key={index}
                                src={URL.createObjectURL(file)}
                                alt={`preview ${index}`}
                                className="image-preview-thumbnail"
                            />
                        ))}
                    </div>

                    <div className='subTitle'>Further details needed for accurate quotes.</div>
                    {/* Need to fill in name, as it was a non-Google registrant */}
                    <div className='inputBox'>
                        <TextField
                            id="name"
                            label="Please share your first and last name"
                            color='secondary'
                            className='input'
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className='inputBox'>
                        <TextField
                            id="phone"
                            label="Please share your contact number"
                            color='secondary'
                            className='input'
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className='inputBox'>
                        <TextField
                            id="email"
                            label="Please share your email"
                            color='secondary'
                            className='input'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                   
                    <div className='inputBox'>
                        <TextField
                            id="home_address"
                            label="Please share your home address (service address) for the travel portion of the quote"
                            color='secondary'
                            className='input'
                            inputRef={inputRef}
                        />
                    </div>

                    {/* Urgency as dropdown */}
                    <div className='inputBox'>
                        <InputLabel id="demo-simple-select-label">How soon do you need this service?</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={urgency}
                            label="urgency"
                            onChange={(e) => setUrgency(e.target.value)}
                            className='input'
                            >
                            <MenuItem value={1}>Urgent (24 hours)</MenuItem>
                            <MenuItem value={2}>Flexible</MenuItem>
                        </Select>
                    </div>

                    <div className='buttonDiv'>
                        <button className='button' onClick={handleSubmit}>Complete</button>
                    </div>
                </div>
            </div>
        );
    } 
    else if ((loading) && (!currentUser)) {

        //Create Google Analytics Event
        ReactGA.event({
            category: 'User',
            action: 'Onboarded User'
        });

        return (
            <div style={pageStyle}>
                <div className='formPanel'>
                    <img src={Logo} className='image'/>
                    <ReactLoading type="balls" color="#000" height={'15rem'} width={'15rem'}/>
                    <h1>Updating your profile</h1>
                </div>
            </div>
        );
    }
    else {

        
        return (
            //Success Page
            /** 
             * This page will show a success message and a Lottie animation
            **/
            <div style={successStyle}>
                <div className="main">
                    <img src={Logo} className='logo' alt="C-Trades Logo" />
                    <div>
                        <Lottie animationData={success} />
                    </div>
                    <div className="textPanel">
                        <h2>We have successfully received your service request. Please allow us a few days to gather and compare quotes from your community.</h2>
                        <h3>To review, cancel or check the status of quotes, please consider creating an account with us for easier accessibiity.</h3>
                        <div className='buttonDiv'>
                            <button className='button' onClick={createAccount}>Create an Account</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OnBoard;
