import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneComponent = ({ images, setImages, swalInstance  }) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (images.length + acceptedFiles.length <= 5) {
            setImages([...images, ...acceptedFiles]);
            
            // Debugging: Log swalInstance
            console.log('swalInstance:', swalInstance);
            if (swalInstance && typeof swalInstance.update === 'function') {
                swalInstance.update();
            } else {
                console.error('swalInstance.update is not a function');
            }
        } else {
            alert('You can only upload up to 5 images.');
        }
    }, [images, setImages]);

    useEffect(() => {
        // Debugging: Log swalInstance
        console.log('swalInstance in useEffect:', swalInstance);
        if (swalInstance && typeof swalInstance.update === 'function') {
            swalInstance.update();
        } else {
            console.error('swalInstance.update is not a function');
        }
    }, [images, swalInstance]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
        maxFiles: 5
    });

    return (
        <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files (up to 5 images)</p>
            <div className="image-preview">
                {images.map((file, index) => (
                    <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`preview ${index}`}
                        className="image-preview-thumbnail"
                    />
                ))}
            </div>
        </div>
    );
};

export default DropzoneComponent;