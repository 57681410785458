import { api } from "./config/axiosConfig"

export const businessAPI = {

    createBusiness: async function (name, ownerName, phone, email, address, city, country, lat, long, website, description) {

        const response = await api.request({
          url: `/business/createBusiness`,
          method: "POST",
          data: {
            name: name,
            contact_name: ownerName,
            phone: phone,
            email: email,
            address: address,
            city: city,
            country: country,
            latitude: lat,
            longitude: long,
            website: website,
            description: description,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    callChatGPT: async function (description) {

        const response = await api.request({
          url: `/quote/callChatGPT`,
          method: "POST",
          params: {
            prompt: description,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    viewRequests: async function (userId) {

        const response = await api.request({
          url: `/quote/viewRequests`,
          method: "GET",
          params: {
            userId: userId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    cancelRequest: async function (requestID) {

        console.log(requestID);

        const response = await api.request({
          url: `/quote/cancelRequest`,
          method: "POST",
          data: {
            requestId: requestID,
          }
        })
        
        // returning the product returned by the API
        return response
    },
};