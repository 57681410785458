// src/reducers/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentQuery: null,
  loading: false, // Flag to indicate user data is being fetched
  error: null, // Store any error that might occur during user actions
};

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setQuery(state, action) {
        state.currentQuery = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export default querySlice.reducer;
export const { setQuery, setLoading, setError } = querySlice.actions;