import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import { quoteAPI } from '../../api/quoteAPI';

//Material UI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { tokenLoginUser } from '../../redux/userActions'; // Import actions

//Navigate
import { useNavigate } from 'react-router-dom';

//Images
import Logo from '../../assets/images/C-TRADES.png';

//CSS
import '../../css/dashboard.css';

//React Icons
import { FaTools } from "react-icons/fa";
import { IoIosCreate } from "react-icons/io";
import { IoDocumentText, IoDocumentTextOutline } from "react-icons/io5";

//SweetAlert2
import Swal from 'sweetalert2';

//Mobile?
import {isMobile} from 'react-device-detect';

//Google Analytics
import ReactGA from 'react-ga4';

//Drag and Drop Images
import ReactDOM from 'react-dom/client';
import DropzoneComponent from './dropZoneComponent'; // Adjust the path to your DropzoneComponent file


function Dashboard () {

    //Redux Object
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    
    //Navigation
    const navigate = useNavigate();

    //Images
    const [images, setImages] = useState([]);
    const imagesRef = useRef(images);

    //Variables
    const [services, setServices] = useState([]);

    //Menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    //HTML Content
    const [htmlContent, setHtmlContent] = useState('');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect (() => {
        
        //Axios call to get existing quotes
        const getQuotes = async (userID) => {

            try {
                const response = await quoteAPI.viewRequests(userID);
                setServices(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        //Google Analytics

        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Returning Customer Dashboard" });

        if (currentUser) {
            getQuotes(currentUser.id);
        }
        else {
            //Persistent Login
            const token = localStorage.getItem('C-TradesUserToken');
            if (token) {
                
                //Check the token
                dispatch(tokenLoginUser(token, navigate));
            }
            else {
                //Navigate to login
                navigate(`/`);
            }
        }
    }, [currentUser]);

    // Re-render the modal content when images change
    useEffect(() => {
        if (Swal.isVisible()) {
            const container = document.getElementById('dropzone-container');
            if (container) {
                const root = ReactDOM.createRoot(container);
                root.render(
                    <DropzoneComponent images={images} setImages={setImages} />
                );
            }
        }
    }, [images]); // This will trigger a re-render when images change

    // Update the ref whenever images state changes
    useEffect(() => {
        imagesRef.current = images;
    }, [images]);
    
    const fetchHtmlContent = async (file) => {
        try {
                   
            //Fetch the HTML content from the file
            const response = await fetch(file);
            const text = await response.text();

            setHtmlContent(text);
        } catch (error) {
            console.error('Error fetching HTML content:', error);
        }
    };


    /** 
     * Handle Terms of Service Click
     */
    const handleTOSClick = async() => {

        //Get HTML content from TC.html
        await fetchHtmlContent('TC.html');

        Swal.fire({
            title: 'Terms of Service',
            html: htmlContent,
            confirmButtonText: 'I Agree',
        });

        //Create Google Analutics Event
        ReactGA.event({
            category: 'Terms of Service',
            action: 'User Agreed',
            label: 'User Agreed to Terms of Service',
        });
    }

    /** 
     * Handle Privacy Policy Click
     */
    const handlePPClick = async() => {

        //Get HTML content from PP.html
        await fetchHtmlContent('PP.html');

        Swal.fire({
            title: 'Privacy Policy',
            html: htmlContent,
            confirmButtonText: 'I Agree',
        });

        //Create Google Analutics Event
        ReactGA.event({
            category: 'Privacy Policy',
            action: 'User Agreed',
            label: 'User Agreed to Privacy Policy',
        });
    }

    /**
     * Left side of the dashboard
     */
    const handleNewRequestClick = async () => {

        try {
            //Open up a new request using SweetAlert2
            const result = await Swal.fire({
                title: 'New Request',
                html: `
                    <label class="centeredInputLabel">Please provide a detailed description of your repair or renovation needs.</label>
                    <textarea id="description" class="swal2-textarea wideTextarea" placeholder="Description" aria-label="Type your message here"></textarea>
                    <div id="dropzone-container"></div>
                    <div class="urgencyToggle">
                        <label for="urgency">Urgent (24 hours):</label>
                        <input type="checkbox" id="urgency" class="largeCheckbox" />
                    </div>
                `,
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                customClass: {
                    inputLabel: 'centeredInputLabel' // Add custom class to inputLabel
                },
                didOpen: () => {
                    const container = document.getElementById('dropzone-container');
                    const root = ReactDOM.createRoot(container);
                    root.render(
                        <DropzoneComponent images={imagesRef.current} setImages={setImages} />
                    );
                },
                preConfirm: () => {
                    const description = document.getElementById('description').value;
                    const isUrgent = document.getElementById('urgency').checked;

               
                    return quoteAPI.createRequest(description, currentUser.name, currentUser.phone, currentUser.email, currentUser.address, currentUser.coordinates.coordinates[0], currentUser.coordinates.coordinates[1], isUrgent ? '24 hours' : 'flexible', imagesRef.current, currentUser.id);
                },
                allowOutsideClick: () => !Swal.isLoading()
            });
            
            if (result.isConfirmed) {
                //Refresh the services list
                const response = await quoteAPI.viewRequests(currentUser.id);

                setServices(response.data);

                //Show a success message
                await Swal.fire({
                    icon: 'success',
                    title: 'Request Submitted',
                    text: 'Your request has been successfully submitted. We will be in touch shortly.',
                });
            }
        } catch (error) {
            // Handle any errors that occur during the request
            await Swal.fire({
                icon: 'error',
                title: 'Submission Error',
                text: error.message,
            });
        }
    };

    const LeftDashboard = () => {
        return (
            <div className='leftDashboard'>
                <h2>Welcome, { currentUser.name }! </h2>
                <div className='menu'>
                    <div className='menu-item'>
                        <FaTools />
                        <span>Services</span>
                    </div>
                    <div className='menu-item' onClick={handleNewRequestClick}>
                        <IoIosCreate />
                        <span>New Request</span>
                    </div>
                </div>
                <div className='menu'>
                    <div className='menu-item1' onClick={handleTOSClick}>
                        <IoDocumentText />
                        <span>Terms of Services</span>
                    </div>
                    <div className='menu-item1' onClick={handlePPClick}>
                        <IoDocumentTextOutline />
                        <span>Privacy Policy</span>
                    </div>
                </div>
            </div>
        )
    }

    /** End Left **/

    /**
     * Right side of the dashboard
     */
    const RightDashboard = () => {
        return (
            <div className='rightDashboard'>
                { servicesPanel() }
            </div>
        )
    }

    const servicesPanel = () => {

        let isDisabled = false;

        //Create a list of Card corresponding to each of the services
        const serviceList = services.map((service) => {

            //Check to see if service was initiated less than 24 hours ago
            const timeDifference = new Date() - new Date(service.createdAt);
            const hoursDifference = timeDifference / (1000 * 60 * 60);
             
            //Disable when over 24 hours
            if (hoursDifference >= 24) {
                isDisabled = true;
            }

            if (isMobile) {

                return (
                    <div className="cardContainer" key={service._id}> {/* Flex container */}
                        <Card 
                            className='cardPanel'
                            sx={{
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', // Raised shadow
                                padding: '1.5rem',
                                margin: '1rem',
                                borderRadius: '0.5rem',
                                maxWidth: '50%'
                            }}>
                            <div className="createdAt">
                                <p><strong>{ new Date(service.createdAt).toLocaleString() }</strong></p>
                            </div>
                            <CardContent>
                                <Typography variant="body2" component="p" className='descriptionText'>
                                    <strong>{ service.description }</strong>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Service Status: <strong>{ service.status }</strong>
                                </Typography>
                            </CardContent>
                            
                        </Card>
                        { service.status === 'pending' ? 
                            <Tooltip title="This button is disabled because more than 24 hours has elapsed and quotes are already being generated.">
                                <Button 
                                    variant="contained" 
                                    className="cancelButton"
                                    sx={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '0.5rem 1rem 0.5rem 1rem',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                        borderRadius: '0.5rem',
                                        '&:hover': {
                                            backgroundColor: 'darkred',
                                        },
                                    }}
                                    onClick={() => cancelRequest(service._id)}
                                    disabled={isDisabled}
                                >
                                    { isDisabled ? "Generating Quotes" : "Cancel Request" }
                                </Button> 
                            </Tooltip> : null 
                        }
                    </div>
                );
            }

            else {
                return (
                    <div className="cardContainer" key={service._id}> {/* Flex container */}
                        <Card 
                            className='cardPanel'
                            sx={{
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', // Raised shadow
                                padding: '1rem',
                                margin: '1rem',
                                borderRadius: '1rem',
                                maxWidth: '50%'
                            }}>
                            <div className="createdAt">
                                <p><strong>{ new Date(service.createdAt).toLocaleString() }</strong></p>
                            </div>
                            <CardContent>
                                <Typography variant="body2" component="p">
                                    <strong>{ service.description }</strong>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Service Status: <strong>{ service.status }</strong>
                                </Typography>
                            </CardContent>
                        </Card>
                        { service.status === 'pending' ? 
                            <Button 
                                variant="contained" 
                                className="cancelButton"
                                sx={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    padding: '1rem 2rem 1rem 2rem',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    borderRadius: '1rem',
                                    '&:hover': {
                                        backgroundColor: 'darkred',
                                    },
                                }}
                                onClick={() => cancelRequest(service._id)}
                                disabled={isDisabled}
                                >
                                { isDisabled ? "Generating Quotes" : "Cancel Request" }
                            </Button> :  null
                        }
                    </div>
                );
            }
        });

        if (isMobile) {
            return (
                <div className='servicesPanel'>
                    <h2>Services History</h2>
                    { serviceList }
                </div>
            )
        }
        else {
            return (
                <div className='servicesPanel'>
                    <h2>Services History</h2>
                    { serviceList }
                </div>
            )
        }
    }

    /** 
     * Cancel Request
     */
    const cancelRequest = async (requestID) => {

        //Use SweetAlert2 to confirm the user wants to cancel the request
        Swal.fire({
            title: 'Are you sure you want to cancel this request?',
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                cancelRequestAPI(requestID);
            }
        });
    };


    /** 
     * API Call
     */
    const cancelRequestAPI = async (requestID) => {
            
        try {
            const response = await quoteAPI.cancelRequest(requestID);
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Request Cancelled',
                    text: 'Your request has been successfully cancelled.',
                });

                //Update the services list by removing the cancelled request from the list
                let newServices = services.filter(service => service._id !== requestID);

                //Update the services list by removing the cancelled request from the list
                setServices(newServices);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    /** End Right **/

    if (currentUser) {   
        if (isMobile) {
            return (
                <>
                    <div className='servicesHeader'>
                        <div className='hamburgerMenu' onClick={toggleMenu}>
                            <MenuIcon />
                        </div>
                        <h2>Welcome, {currentUser.name}! </h2>
                    </div>
                    <div className='container'>
                        {isMenuOpen && (
                            <div className='mobileMenu'>
                                <ul>
                                    <li>
                                        <div className='menu-item'>
                                            <FaTools />
                                            <span>Services</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='menu-item' onClick={handleNewRequestClick}>
                                            <IoIosCreate />
                                            <span>New Request</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='menu-item1' onClick={handleTOSClick}>
                                            <IoDocumentText />
                                            <span>Terms of Services</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='menu-item1' onClick={handlePPClick}>
                                            <IoDocumentTextOutline />
                                            <span>Privacy Policy</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <RightDashboard />
                        <img src={Logo} className='logo' alt="C-Trades Logo" />
                    </div>
                </>
            )
        }
        else {
            return (
                <div className='container'>
                    <LeftDashboard />
                    <RightDashboard />
                    <img src={Logo} className='logo' alt="C-Trades Logo" />
                </div>
            )
        }
    }
    else {
        return (
            <div className='container'>
                <h1>Loading...</h1>
            </div>
        )
    }

}

export default Dashboard;