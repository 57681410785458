import { api } from "./config/axiosConfig"

export const quoteAPI = {

    createRequest: async function (description, name, phone, email, address, lat, long, urgency, images, userId) {

        console.log(description, name, phone, email, address, lat, long, urgency, images, userId);
      
        // Create a new FormData object
        const formData = new FormData();

        // Append text fields to the FormData object
        formData.append('description', description);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('latitude', lat);
        formData.append('longitude', long);
        formData.append('required_urgency', urgency);

        // Append the user ID to the FormData object if it is not undefined
        if (userId) {
          formData.append('user', userId);
        }

        // Append images to the FormData object
        for (let i = 0; i < images.length; i++) {
          formData.append('files', images[i]);
        }

        /*const response = await api.request({
          url: `/quote/createRequest`,
          method: "POST",
          data: {
            description: description,
            name: name,
            phone: phone,
            email: email,
            address: address,
            latitude: lat,
            longitude: long,
            required_urgency: urgency,
            user: userId,
          },
        })*/
        // Send the FormData object in the POST request
        const response = await api.request({
            url: `/quote/createRequest`,
            method: "POST",
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        // returning the product returned by the API
        return response
    },

    callChatGPT: async function (description) {

        const response = await api.request({
          url: `/quote/callChatGPT`,
          method: "POST",
          params: {
            prompt: description,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    viewRequests: async function (userId) {

        const response = await api.request({
          url: `/quote/viewRequests`,
          method: "GET",
          params: {
            userId: userId,
          },
        })
        
        // returning the product returned by the API
        return response
    },

    cancelRequest: async function (requestID) {

        console.log(requestID);

        const response = await api.request({
          url: `/quote/cancelRequest`,
          method: "POST",
          data: {
            requestId: requestID,
          }
        })
        
        // returning the product returned by the API
        return response
    },
};